// import service from "service";
import service from "./axios_service";
import {
    useLocation,
    useNavigate,
    useParams,
  } from "react-router-dom";
import moment from "moment";
import {API} from "../apis";
import { toast } from 'react-toastify';

// const userLogin = (data) => {
//     let url = `${API.login}`;
//     let url1="https://jsonplaceholder.typicode.com/todos/1";
//     // return service.get(url1)
//     return dispatch => {
        
//     return service.post(url,data)
//             .then((res) => {
//                return res?.token??"";
//             })
//             .catch((error) => {
//                return "";
//             });
//         }
    
// }


const getClientDetails = () => {
    let url = `${API.clientDetails}`;
    return dispatch => {
    // const token = localStorage.getItem("token");

        service.get(url)
            .then((res) => {
                dispatch({
                    type: "GET_CLIENT_DETAILS",
                    data: res.data,
                });
            })
            .catch((error) => {
            })
    }
}
const getProducts = () => {
    let url = `${API.products}`;
    return dispatch => {
    // const token = localStorage.getItem("token");

        service.get(url, {
            // headers: {
            //     "Authorization": `Bearer ${token}`,
            //     "Content-Type": "application/json"
            // }
        })
            .then((res) => {
                res.data.products.map((i)=>{
                        i["qty"]=0;
                        return i;
                    });
                dispatch({
                    type: "GET_PRODUCTS",
                    data: res.data.products,
                });
            })
            .catch((error) => {
            })
    }
}

const getSuggesstions = () => {
    const currentDayName = moment().format('dddd');
    console.log('Analytics Base URL:', API.BASE_URL_ANALYTICS);

    let url = `${API.BASE_URL_ANALYTICS}suggestions/${currentDayName}`;
    return dispatch => {

        service.get(url)
            .then((res) => {
                dispatch({
                    type: "GET_SUGGESTIONS",
                    data: res.data?.suggestions??[],
                });
            })
            .catch((error) => {
            })
    }
}
const placeOrders = (payload) => {
    let url = `${API.placeOrder}`;
    return dispatch => {
    const token = localStorage.getItem("token");

      return  service.post(url,payload ,{
            
        })
            .then((res) => {
                dispatch(getProducts());
                return true;
            })
            .catch((error) => {
                return false;
            })
    }
}

const getInvoicess = (from,to) => {
    let url = `${API.invoices}?startDate=${from}&endDate=${to}`;
    return dispatch => {
    const token = localStorage.getItem("token");

        service.get(url, {
            
        })
            .then((res) => {
                dispatch({
                    type: "GET_INVOICES",
                    data: res.data?.invoices??[],
                });
            })
            .catch((error) => {
            })
    }
}


const getMyOrders = (from,to) => {
    let url = `${API.orderList}?startDate=${from}&endDate=${to}`;
    return dispatch => {
    const token = localStorage.getItem("token");

        service.get(url, {
            withCredentials:true,
            
        })
            .then((res) => {
                dispatch({
                    type: "GET_MY_ORDERS",
                    data: res.data,
                });
            })
            .catch((error) => {
            })
    }
}


const getOrderItems = (id) => {
    let url = `api/v1/b2bclients/orders/${id}/items`;
    return dispatch => {
    const token = localStorage.getItem("token");

       return service.get(url, {
            withCredentials:true,
            
        })
            .then((res) => {
                return res.data??[];
            })
            .catch((error) => {
                return [];
            })
    }
}


const getOrderIds = () => {
    let url = `${API.orderListIds}`;
    return dispatch => {
    const token = localStorage.getItem("token");

        service.get(url, {
            withCredentials:true,
            
        })
            .then((res) => {
                dispatch({
                    type: "GET__ORDERS_IDS",
                    data: res.data,
                });
            })
            .catch((error) => {
            })
    }
}


const getOrderDetails = (id) => {
    let url = `${API.orderList}/${id}`;
    return dispatch => {
    const token = localStorage.getItem("token");

       return service.get(url, {
            withCredentials:true,
            
        })
            .then((res) => {
               return res.data;
            })
            .catch((error) => {
                return null;
            })
    }
}


const emptyCart = (data) => {
    let url = ``;
    return dispatch => {
        const newData = data.map((row, i)=> {
            let u= {...row,qty:0, isUpdate:false};
            return u;
          }
          );
        dispatch({
            type: "GET_PRODUCTS",
            data: newData,
        });
    }
}

const getBarchartData = (type,month,year) => {
    let url = `${API.BASE_URL_ANALYTICS}${type}/month/${year}/${month}`;
    return dispatch => {
    const token = localStorage.getItem("token");

        service.get(url, {
            withCredentials:true,
            
        })
            .then((res) => {
            if(type==="expenditure"){
                let data = {
                    ...res.data,
                    expenditure: [...res.data.expenditure], // shallow copy the consumption array
                };
                
                // If there are objects inside the consumption array that can be mutated,
                // you need to deep copy them as well:
                let mydata = data.expenditure.map(i => ({
                    id: i.id,
                    percentage: i.percentage,
                    name: i.name,
                }));
                
                data.myData = mydata;
                dispatch({
                    type: "GET_EXPENDITURE_VERTICAL",
                    data: data,
                });
            }else{
                let data = {
                    ...res.data,
                    consumption: [...res.data.consumption], // shallow copy the consumption array
                };
                
                // If there are objects inside the consumption array that can be mutated,
                // you need to deep copy them as well:
                let mydata = data.consumption.map(i => ({
                    id: i.id,
                    percentage: i.percentage,
                    name: i.name,
                }));
                
                data.myData = mydata;
                
                dispatch({
                    type: "GET_CONSUMPTION_VERTICAL",
                    data: data,
                });
            }
          
            })
            .catch((error) => {
            })
    }
}


const getPiechartData = (type) => {
    let url = `${API.BASE_URL_ANALYTICS}${type}/weekly-summary/`;
    return dispatch => {
    const token = localStorage.getItem("token");

        service.get(url, {
            withCredentials:true,
            
        })
            .then((res) => {
            if(type==="expenditure"){
                dispatch({
                    type: "GET_EXPENDITURE_PIE",
                    data: res.data.weekly_summary,
                });
            }else{
                dispatch({
                    type: "GET_CONSUMPTION_PIE",
                    data: res.data.weekly_summary,
                });
            }
          
            })
            .catch((error) => {
            })
    }
}


const gethorizontalChartData = (type) => {
    let url = `${API.BASE_URL_ANALYTICS}${type}/by-day/`;
    return dispatch => {
    const token = localStorage.getItem("token");

        service.get(url, {
            withCredentials:true,
            
        })
            .then((res) => {
            if(type==="expenditure"){
                dispatch({
                    type: "GET_EXPENDITURE_HORIZONTAL",
                    data: res.data.expenditure_by_day,
                });
            }else{
                dispatch({
                    type: "GET_CONSUMPTION_HORIZONTAL",
                    data:res.data.consumption_by_day
                });
            }
          
            })
            .catch((error) => {
            })
    }
}

const downloadInvoice = (item) => {
    toast.info("Invoice preparing progress...")
    const url = `api/v1/b2bclients/invoices/${item.order_id}/download_invoice`; // replace with your file URL
    return dispatch => {
        // const token = localStorage.getItem("token");
    
            service.get(url, {
                withCredentials:true,
                responseType:"blob",
            
            })
                .then((res) => {
                    ////Open
                    const blob = new Blob([res.data], { type: 'application/pdf' });
                    const blobUrl = window.URL.createObjectURL(blob);
                    window.open(blobUrl, '_blank');

                    /////download
                    // const blob = new Blob([response.data], { type: response.data.type });
                    // const link = document.createElement('a');
                    // link.href = window.URL.createObjectURL(blob);
                    // link.download = 'file.pdf'; // Set the file name
                    // link.click(); // Trigger the download
                })
                .catch((error) => {
    toast.error("Invoice preparing failed.")

                })
        }

  };

  
const downloadMyOrder = (item) => {
    const url = `api/v1/b2bclients/orders/${item.order_id}/download_summary`; // replace with your file URL
    return dispatch => {
        // const token = localStorage.getItem("token");
    
            service.get(url, {
                withCredentials:true,
                responseType:"blob",
            
            })
                .then((res) => {
                    ////Open
                    const blob = new Blob([res.data], { type: 'application/pdf' });
                    const blobUrl = window.URL.createObjectURL(blob);
                    window.open(blobUrl, '_blank');

                })
                .catch((error) => {
                })
        }

  };

//  const logOut=()=>{
//     let navigate = useNavigate();

//     localStorage.clear();
//     navigate("/login");
//  }
export {getOrderItems,getOrderDetails,getOrderIds, getProducts,emptyCart,getInvoicess,getMyOrders,placeOrders,getBarchartData,getPiechartData,gethorizontalChartData,downloadInvoice,getClientDetails,getSuggesstions, downloadMyOrder}

