import React, {useState, useEffect} from "react";

import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Carrot from "../../assets/cart/carrot.jpg";
import { GrFormClose } from "react-icons/gr";
import moment from "moment";
import { getOrderItems } from "../../services/Actions/client";

// import { useState } from "react";

 function MyOrdersModal(props) {
// const order= props.order;
// console.log("check ;loasdasd", order)
const [rows, setRows] = useState([]);
const [order, setOrder] = useState({});
const [datePlaced, setDatePlaced] = useState("");
const [deliveryDate, setDeliveryDate] = useState("");

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    setOrder(props.order)
    console.log("props ssdfsdfsdfdsfdsfdsf", props)
    let res = props.dispatch(getOrderItems(props.order.order_id)).then((val)=>{
      console.log("sucesssss", val)
      setRows(val);

    })
    const formattedDate = moment(props.order.created_at, "DD/MM/YYYY hh:mm a").format("dddd, MM/DD/YY, h:mm A");
    const tomorrowDate = moment(props.order.created_at, "DD/MM/YYYY hh:mm a").add(1, 'days').format("dddd, MM/DD/YY");
    setDatePlaced(formattedDate)
    setDeliveryDate(tomorrowDate)
    console.log("asdsadasdasdasdasdasdasdasdasd",order)
  }, [props]);
  useEffect(() => {
  }, []);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="responsive-dialog-title"
      className="myorderdetailsmodalpopupsection"
      fullWidth={true}
      maxWidth="md" // Set this to 'lg' or 'xl' for larger modal
    >
      <DialogTitle id="responsive-dialog-title">
        <div className="orderdetailscontentheadingsection mx-5">
          <div className="orderdetailscontentheading d-flex">
            <h3 className="col-4 mb-0">Order Number :</h3>
            <h3 className="col-8 mb-0">{order?.order_number??""} </h3>
          </div>
          <div className="orderdetailscontentheading d-flex">
            <h3 className="col-4 mb-0">Order Placed :</h3>
            <h3 className="col-8 mb-0">{datePlaced} </h3>
          </div>
          <div className="orderdetailscontentheading d-flex">
            <h3 className="col-4 mb-0">Delivery Time :</h3>
            <h3 className="col-8 mb-0">{deliveryDate} </h3>
          </div>
        </div>
      </DialogTitle>
      <div className="horizontallinesection">
      <hr></hr>
      </div>
      <DialogContent>
        <DialogContentText>
          <div className="orderdetailscontentbodysection">
           {rows&& rows.map((i)=> <div className="orderdetailscontentbody d-flex">
                <div className="col">
                    <image src={Carrot} alt="image" className="image-section"></image>
                    <div>
                        <h3>{i?.product_name??""}</h3>
                        <div>
                            <h6>Units : {i?.unit??""}</h6>
                            <h6>24 hrs delivery</h6>
                        </div>
                    </div>                
                </div>
                <div className="col-2">
                    <input
                        type="text"
                        className="quantityvaluesection"
                        value={i?.quantity??""}
                    ></input>
                </div>
            </div>)}
            {/* <div className="orderdetailscontentbody d-flex">
                <div className="col">
                    <image src={Carrot} alt="image" className="image-section"></image>
                    <div>
                        <h3>Carrots</h3>
                        <div>
                            <h6>Units : Kgs</h6>
                            <h6>24 hrs delivery</h6>
                        </div>
                    </div>                
                </div>
                <div className="col-2">
                    <input
                        type="text"
                        className="quantityvaluesection"
                        value={2}
                    ></input>
                </div>
            </div>
            <div className="orderdetailscontentbody d-flex">
                <div className="col">
                    <image src={Carrot} alt="image" className="image-section"></image>
                    <div>
                        <h3>Carrots</h3>
                        <div>
                            <h6>Units : Kgs</h6>
                            <h6>24 hrs delivery</h6>
                        </div>
                    </div>                
                </div>
                <div className="col-2">
                    <input
                        type="text"
                        className="quantityvaluesection"
                        value={2}
                    ></input>
                </div>
            </div> */}
          </div>
          {/* <div className="col-12 d-flex justify-content-end mt-5">
          <div className="totalquantitysection col-6">
            <div className="totalquantity d-flex">
                <h3 className="col-6">Receiving Dates:</h3>
                <h3 className="col-6 d-flex justify-content-end">Monday, 07/22/24</h3>
            </div>
            <div className="totalquantity d-flex">
                <h3 className="col-6">Total Line Items:</h3>
                <h3 className="col-6 d-flex justify-content-end">6</h3>
            </div>
            <div className="totalquantity d-flex">
                <h3 className="col-6">Total Quantity</h3>
                <h3 className="col-6 d-flex justify-content-end">14</h3>
            </div>
          </div>
          </div> */}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={props.handleClose} className="closebutton">
        <GrFormClose style={{color: "#fff", fontSize: "45px"}}/>
        </Button>
      </DialogActions>
    </Dialog>
  );
}


function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyOrdersModal)
);