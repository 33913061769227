import React, {useState, useEffect} from "react";
// import Tab from "@mui/material/Tab";

import { connect } from "react-redux";
import withRouter from "../Router/withRouter";

import moment from "moment";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { LuDownload } from "react-icons/lu";

import {getInvoicess, downloadInvoice} from "../../services/Actions/client"
import DatePicker from 'react-datepicker';
import NoItems from "../../Components/no_items";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

  // function createData(name, unit, quantity, availability) {
  //   return { name, unit, quantity, availability };
  // }

  // const rows = [
  //   createData(
  //     "FT86578849849",
  //     "07/20/2024",
  //     <div className="statussection inprogress">
  //       IN PROGRESS{" "}
  //       <span className="download-icon">
  //         <LuDownload />{" "}
  //       </span>
  //     </div>
  //   ),
  //   createData(
  //     "FT85793903855",
  //     "07/03/2024",
  //     <div className="statussection delivered">
  //       DELIVERED{" "}
  //       <span className="download-icon">
  //         <LuDownload />{" "}
  //       </span>
  //     </div>
  //   ),
  //   createData(
  //     "FT90233756389",
  //     "06/23/2024",
  //     <div className="statussection cancelled">
  //       CANCELLED{" "}
  //       <span className="download-icon">
  //         <LuDownload />{" "}
  //       </span>
  //     </div>
  //   ),
  //   createData(
  //     "FT68573685830",
  //     "06/22/2024",
  //     <div className="statussection delivered">
  //       DELIVERED{" "}
  //       <span className="download-icon">
  //         <LuDownload />{" "}
  //       </span>
  //     </div>
  //   ),
  // ];

function Invoices(props) {
  const [invoices, setInvoices] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [search, setSearch] = useState("");
  
  useEffect(()=>{
    getInvoicesApi("","")
      },[])

  useEffect(() => {
    // downloadInvoice();
    setInvoices(props.invoices);
    
  }, [props]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const onSearch=(e)=>{
    let value=e.target.value;
    setSearch(value);
  }
  const donloadInvoice=(i)=>{
    if(i.status=="approved"){
    props.dispatch(downloadInvoice(i));
    }
  }
  const getInvoicesApi=(from,to)=>{
    props.dispatch(getInvoicess(from,to));
  }
const handleMonthChange = (date,setDate) => {
  setDate(date)
};
const clearDate =()=>{
  setStartDate(null);
  setEndDate(null);
}
const applyFilter =()=>{
  const from = moment(startDate).format('YYYY-MM-DD');

  const to = moment(endDate).format('YYYY-MM-DD');

  getInvoicesApi(from,to)
}
  return (
    <div className="w-full">
      {/* <ContentHeader />
      <Header />
      <Dashboards /> */}
      <div className="container ">
        <div className="row">
          <div className="productssection">
            <div className="col-lg-12 mb-3">
              <h3>Invoices</h3>
            </div>
            {invoices.length>0&&<div className="col-lg-12 d-flex align-items-center justify-content-between mb-5">
              <div className="inputseachsection">
                {/* <input
                  className="form-control mr-sm-2"
                  type="search"
                  placeholder="Search by Date..."
                  aria-label="Search"
                  onChange={onSearch}
                /> */}
              </div>
              <div className="d-flex justify-content-end">
                <Stack spacing={2}>
                <Pagination count={Math.ceil(invoices.filter((item) =>
       search?( item.order_id.toLowerCase().includes(search.toLowerCase())||item.status.toLowerCase().includes(search.toLowerCase())):true
      ).length / rowsPerPage)}  page={page}
        onChange={handleChangePage} showFirstButton showLastButton />
                </Stack>
              </div>
            </div>}
            {invoices.length>0&&<div className="d-flex align-items-center mb-5">
              {/* <div className="col-2">
                <h3>Select Date Range:</h3>
              </div> */}
              <div className="fromDateSection col-3 d-flex align-items-center me-5">
                <h5 className="me-3">From</h5>
                <DatePicker
                  selected={startDate}
                  placeholderText="dd/mm/yyyy"

                  onChange={(date) => handleMonthChange(date,setStartDate)}
                  // onYearChange={(date) => handleMonthChange(date)}

                  dateFormat="dd/MM/yyyy"
                  // showMonthYearPicker
                  className="form-control"
                />
              </div>
              <div className="toDateSection col-3 d-flex align-items-center">
                <h5 className="me-3">To</h5>
                <DatePicker
                  selected={endDate}
                  placeholderText="dd/mm/yyyy"
                  onChange={(date) => handleMonthChange(date,setEndDate)}
                  // onYearChange={(date) => handleMonthChange(date)}

                  dateFormat="dd/MM/yyyy"
                  // showMonthYearPicker
                  className="form-control"
                />
              </div>
              <div className="col-2  d-flex">
              <div className="col-6 d-flex justify-content-between align-items-center">
                  <button onClick={clearDate} className="button-section greyButton">Clear</button>
                </div>
                <div className="col-6 d-flex justify-content-between align-items-center">
                  <button onClick={applyFilter} className="button-section">Apply</button>
                </div>
                </div>
            </div>}
            {invoices.length>0?<TableContainer
              component={Paper} className="table-responsive"  style={{ borderRadius: "0px"}}           
            >
              <Table className="table table-bordered mb-5" style={{ marginBottom: "0px", border: "1px solid black"}}  sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Invoice Number</StyledTableCell>
                    <StyledTableCell align="center">
                      Date Placed
                    </StyledTableCell>
                    <StyledTableCell align="center">Status</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoices.filter((item) =>
       search?( item.order_id.toLowerCase().includes(search.toLowerCase())||item.status.toLowerCase().includes(search.toLowerCase())):true
      ).slice((page-1) * rowsPerPage, page * rowsPerPage).map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell component="th" scope="row" align="left">
                        {row.invoice_number}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {/* {moment(row.generated_at).format('MM/DD/YYYY')} */}
                        {row.generated_at}
                      </StyledTableCell>
                      <StyledTableCell  onClick={()=>donloadInvoice(row)} align="center">
                      <div className="statussection delivered">
        {row.status}
        {row.status=="approved" &&<span className="pl-2 download-icon">
          <LuDownload />{" "}
        </span>}
      </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>: <NoItems />  }          
          </div>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {
    invoices: state.invoice.invoices??[]
  };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Invoices));